import {useNuxtApp} from '#app';

export default defineNuxtPlugin((nuxtApp) => {

	const gaTagsByLocale = nuxtApp.$config.public.gaTagsByLocale;
	//
	// // console.log('domains', domains);
	//
	// const url = useRequestURL();
	// const host = url.host;
	// // const host = nuxtApp.ssrContext?.event.node.req.headers.host
	// const locale = domains[host] ?? 'en';
	// // console.log('- host', host, url.pathname, 'detectLocale:', locale);
	//
	// // store.lang = domains[host] ?? 'en';
	// nuxtApp.$i18n.setLocale(locale);
	// console.log('locale', locale)

	console.debug('plugin GA', nuxtApp.$i18n.locale.value)

	// window.dataLayer = window.dataLayer || [];
	// function gtag(){dataLayer.push(arguments);}
	// gtag('js', new Date());
	//
	// gtag('config', 'G-Z6KBJM98PX');
	const gtagId = gaTagsByLocale[nuxtApp.$i18n.locale.value] ?? gaTagsByLocale['default'];

	if (process.client) {
		function gtag() {
			window.dataLayer.push(arguments);
		}

		window.dataLayer = window.dataLayer || [];

		gtag("js", new Date());
		gtag("config", gtagId);
	}

	useHead({
		script: [
			{
				src: `https://www.googletagmanager.com/gtag/js?id=${gtagId}`,
				async: true,
			},
		],
	});
})
