import {useStore} from '~/stores/store';
import {useNuxtApp} from '#app';

export default defineNuxtRouteMiddleware(async to => {
    const store = useStore();
    const nuxtApp = useNuxtApp();

    // console.log('%c- middleware detectMobile', 'color:#0a0');

    if (process.client) {
		store.isMobile = nuxtApp.$helpers.detectMobile();
    }
})
