import {defineStore} from 'pinia';
import {useApi} from '~/composables/api';

export const useStore = defineStore('store', {
	state: () => ({
		isMobile: false,
        isOpenMobileMenu: false,
		approves: [],
		eventTypes: [],
		arts: [],
		blockchains: [],
		utilities: [],
		sortBy: [],
		prefooter: [],
		calendar_defaults: {},
		quick_access: {},
		total_supply: {},
		calendar_default_range: {}
	}),
	actions: {
		async fetchEntities() {
			// console.log('fetchEntities()', this.approved.length, this.eventTypes.length, this.arts.length, this.blockchains.length, this.utilities.length, this.sortBy.length, this.prefooter.length);
			// console.log(this.approved.length && this.eventTypes.length && this.arts.length && this.blockchains.length && this.utilities.length && this.sortBy.length && this.prefooter.length)

            if (this.approves.length && this.eventTypes.length && this.arts.length && this.blockchains.length && this.utilities.length && this.sortBy.length && this.prefooter.length) {
                return;
            }

            const api = useApi();
			const response = await api.entities.fetchEntities();

			if (response) {
				this.quick_access = response.quick_access
				this.approves = response.approves
				this.eventTypes = response.event_types
				this.arts = response.arts
				this.blockchains = response.blockchains
				this.utilities = response.utilities
				this.sortBy = response.sort_by
				this.prefooter = response.prefooter
				this.calendar_default_range = response.calendar_default_range
			}
		},
	},
})

