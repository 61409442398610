import validate from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import detect_45language_45global from "/__w/doxxy-frontend/doxxy-frontend/middleware/detectLanguage.global.js";
import detect_45mobile_45global from "/__w/doxxy-frontend/doxxy-frontend/middleware/detectMobile.global.js";
import manifest_45route_45rule from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  detect_45language_45global,
  detect_45mobile_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "detect-url-news": () => import("/__w/doxxy-frontend/doxxy-frontend/middleware/detectUrlNews.js"),
  entities: () => import("/__w/doxxy-frontend/doxxy-frontend/middleware/entities.js"),
  "entities-news": () => import("/__w/doxxy-frontend/doxxy-frontend/middleware/entitiesNews.js")
}