<template>
    <nav class="header-nav-list" :class="classNames">
        <div class="header-nav body-1" v-for="item in menuItems" :key="item.title">
            <nuxt-link :to="item.link" class="white_a">{{item.title}}</nuxt-link>
        </div>
        <i class="icon-plus header-menu-close" @click="store.isOpenMobileMenu = false"></i>
    </nav>
</template>

<script>
import { useStore } from "~/stores/store";
import langRoutes from "~/langRoutes";

export default {
    name: 'Navigation',
    setup() {
        const store = useStore();

        return { store };
    },
    computed: {
        classNames() {
            return {
                open: this.store.isOpenMobileMenu
            }
        },
        menuItems() {
            return [
                { title: this.$t('events'), link: langRoutes['nft-events'].children['index'][this.$i18n.locale].path },
                { title: this.$t('nftCollections'), link: langRoutes['nft-collections'].children['index'][this.$i18n.locale].path },
                { title: this.$t('studios'), link: langRoutes['nft-studios'].children['index'][this.$i18n.locale].path },
                { title: this.$t('news'), link: `${this.$config.public.HOST_FRONTEND}news/`}
            ]
        }
    },
	watch: {
		// 'store.isOpenMobileMenu'(val) {
			// const modals = document.querySelectorAll('.modal-overlay');
			// if (val) {
			// 	document.body.classList.add('modal-open');
			// 	return;
			// }
			// if (modals.length < 2) {
			// 	document.body.classList.remove('modal-open');
			// }
		// }
	},
    methods: {
        onOpenMyOrders(e) {
            e.stopPropagation();
            this.store.isOpenMobileMenu = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.white_a {
	color: white;
}
.header-nav-list {
    display: flex;
    gap: 3.2rem;

    @media (max-width: 990px) {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 270px;
        background-color: $black;
        z-index: 9;
        flex-direction: column;
        box-sizing: border-box;
        padding: 8rem 2rem 2rem;
        gap: 1.6rem;
        border-left: 0.1rem solid $dark-2;
        overflow: auto;
        transition: translate .5s;

        &:not(.open){
            translate: 100% 0;
        }
    }
}
</style>
