import { default as indexw2em0H47YDMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/index.vue?macro=true";
import { default as _91influencer_93uW5DSK09wLMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/news/[influencer].vue?macro=true";
import { default as indexnTzc6nO5LdMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/news/index.vue?macro=true";
import { default as _91article_93At9aWA6rq7Meta } from "/__w/doxxy-frontend/doxxy-frontend/pages/news/p/[article].vue?macro=true";
import { default as _91tag_93dOcXOBLwCRMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/news/t/[tag].vue?macro=true";
import { default as _91collection_93xncQ00QfPnMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/collections/[collection].vue?macro=true";
import { default as indexiXh4sPzuLpMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/collections/index.vue?macro=true";
import { default as _91event_93qwcOadGjo4Meta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/events/[event].vue?macro=true";
import { default as index73Y2SnWg8bMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/events/index.vue?macro=true";
import { default as index0TIFQzrRTkMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/index.vue?macro=true";
import { default as privacy82Z3fr6OkiMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/privacy.vue?macro=true";
import { default as _91studio_93GANW7fPyCdMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/studios/[studio].vue?macro=true";
import { default as indexXwITLxuVtLMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/studios/index.vue?macro=true";
import { default as termsh26i1WUjcvMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/nft/terms.vue?macro=true";
import { default as indexlKznW13o9EMeta } from "/__w/doxxy-frontend/doxxy-frontend/pages/privacy-policy/index.vue?macro=true";
import { default as indexa4ytKYhdFkMeta } from "~/pages/nft/events/index.vue?macro=true";
import { default as indexIxOFaDkYYbMeta } from "~/pages/nft/collections/index.vue?macro=true";
import { default as indext7JJSfHSuiMeta } from "~/pages/nft/studios/index.vue?macro=true";
import { default as _91event_93etXCcdD4P4Meta } from "~/pages/nft/events/[event].vue?macro=true";
import { default as _91collection_93dYNaxzt6BxMeta } from "~/pages/nft/collections/[collection].vue?macro=true";
import { default as _91studio_93RX2eR2y5QMMeta } from "~/pages/nft/studios/[studio].vue?macro=true";
import { default as indexmZZiHwHNnbMeta } from "~/pages/news/index.vue?macro=true";
export default [
  {
    name: indexw2em0H47YDMeta?.name ?? "index",
    path: indexw2em0H47YDMeta?.path ?? "/",
    meta: indexw2em0H47YDMeta || {},
    alias: indexw2em0H47YDMeta?.alias || [],
    redirect: indexw2em0H47YDMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91influencer_93uW5DSK09wLMeta?.name ?? "news-influencer",
    path: _91influencer_93uW5DSK09wLMeta?.path ?? "/news/:influencer()",
    meta: _91influencer_93uW5DSK09wLMeta || {},
    alias: _91influencer_93uW5DSK09wLMeta?.alias || [],
    redirect: _91influencer_93uW5DSK09wLMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/news/[influencer].vue").then(m => m.default || m)
  },
  {
    name: indexnTzc6nO5LdMeta?.name ?? "news",
    path: indexnTzc6nO5LdMeta?.path ?? "/news",
    meta: indexnTzc6nO5LdMeta || {},
    alias: indexnTzc6nO5LdMeta?.alias || [],
    redirect: indexnTzc6nO5LdMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91article_93At9aWA6rq7Meta?.name ?? "news-p-article",
    path: _91article_93At9aWA6rq7Meta?.path ?? "/news/p/:article()",
    meta: _91article_93At9aWA6rq7Meta || {},
    alias: _91article_93At9aWA6rq7Meta?.alias || [],
    redirect: _91article_93At9aWA6rq7Meta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/news/p/[article].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93dOcXOBLwCRMeta?.name ?? "news-t-tag",
    path: _91tag_93dOcXOBLwCRMeta?.path ?? "/news/t/:tag()",
    meta: _91tag_93dOcXOBLwCRMeta || {},
    alias: _91tag_93dOcXOBLwCRMeta?.alias || [],
    redirect: _91tag_93dOcXOBLwCRMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/news/t/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93xncQ00QfPnMeta?.name ?? "nft-collections-collection",
    path: _91collection_93xncQ00QfPnMeta?.path ?? "/nft/collections/:collection()",
    meta: _91collection_93xncQ00QfPnMeta || {},
    alias: _91collection_93xncQ00QfPnMeta?.alias || [],
    redirect: _91collection_93xncQ00QfPnMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: indexiXh4sPzuLpMeta?.name ?? "nft-collections",
    path: indexiXh4sPzuLpMeta?.path ?? "/nft/collections",
    meta: indexiXh4sPzuLpMeta || {},
    alias: indexiXh4sPzuLpMeta?.alias || [],
    redirect: indexiXh4sPzuLpMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: _91event_93qwcOadGjo4Meta?.name ?? "nft-events-event",
    path: _91event_93qwcOadGjo4Meta?.path ?? "/nft/events/:event()",
    meta: _91event_93qwcOadGjo4Meta || {},
    alias: _91event_93qwcOadGjo4Meta?.alias || [],
    redirect: _91event_93qwcOadGjo4Meta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: index73Y2SnWg8bMeta?.name ?? "nft-events",
    path: index73Y2SnWg8bMeta?.path ?? "/nft/events",
    meta: index73Y2SnWg8bMeta || {},
    alias: index73Y2SnWg8bMeta?.alias || [],
    redirect: index73Y2SnWg8bMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: index0TIFQzrRTkMeta?.name ?? "nft",
    path: index0TIFQzrRTkMeta?.path ?? "/nft",
    meta: index0TIFQzrRTkMeta || {},
    alias: index0TIFQzrRTkMeta?.alias || [],
    redirect: index0TIFQzrRTkMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/index.vue").then(m => m.default || m)
  },
  {
    name: privacy82Z3fr6OkiMeta?.name ?? "nft-privacy",
    path: privacy82Z3fr6OkiMeta?.path ?? "/nft/privacy",
    meta: privacy82Z3fr6OkiMeta || {},
    alias: privacy82Z3fr6OkiMeta?.alias || [],
    redirect: privacy82Z3fr6OkiMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91studio_93GANW7fPyCdMeta?.name ?? "nft-studios-studio",
    path: _91studio_93GANW7fPyCdMeta?.path ?? "/nft/studios/:studio()",
    meta: _91studio_93GANW7fPyCdMeta || {},
    alias: _91studio_93GANW7fPyCdMeta?.alias || [],
    redirect: _91studio_93GANW7fPyCdMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: indexXwITLxuVtLMeta?.name ?? "nft-studios",
    path: indexXwITLxuVtLMeta?.path ?? "/nft/studios",
    meta: indexXwITLxuVtLMeta || {},
    alias: indexXwITLxuVtLMeta?.alias || [],
    redirect: indexXwITLxuVtLMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: termsh26i1WUjcvMeta?.name ?? "nft-terms",
    path: termsh26i1WUjcvMeta?.path ?? "/nft/terms",
    meta: termsh26i1WUjcvMeta || {},
    alias: termsh26i1WUjcvMeta?.alias || [],
    redirect: termsh26i1WUjcvMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/nft/terms.vue").then(m => m.default || m)
  },
  {
    name: indexlKznW13o9EMeta?.name ?? "privacy-policy",
    path: indexlKznW13o9EMeta?.path ?? "/privacy-policy",
    meta: indexlKznW13o9EMeta || {},
    alias: indexlKznW13o9EMeta?.alias || [],
    redirect: indexlKznW13o9EMeta?.redirect,
    component: () => import("/__w/doxxy-frontend/doxxy-frontend/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-de",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-es-mx",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-fr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/evenements/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-jp",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-pt-br",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-ru",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/sobytiya/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-tr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/etkinlikler/",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/event-type/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-de",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/event-type/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-es-mx",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/tipo-de-evento/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-fr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/evenements/type-devenement/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-jp",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/event-type/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-pt-br",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/tipo-de-evento/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-ru",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/sobytiya/tip-sobytiya/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-event-type-tr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/etkinlikler/etkinlik-turu/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-de",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-es-mx",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-fr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/evenements/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-jp",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-pt-br",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-ru",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/sobytiya/blokcheyn/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-blockchain-tr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/etkinlikler/blockchain/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/art/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-de",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/kunst/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-es-mx",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/arte/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-fr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/evenements/art/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-jp",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/art/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-pt-br",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/arte/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-ru",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/sobytiya/iskusstvo/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-art-tr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/etkinlikler/sanat/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-de",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-es-mx",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-fr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/evenements/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-jp",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/events/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-pt-br",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/eventos/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-ru",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/sobytiya/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexa4ytKYhdFkMeta?.name ?? "nft-events-utilities-tr",
    path: indexa4ytKYhdFkMeta?.path ?? "/nft/etkinlikler/utilities/:filterValue()",
    meta: indexa4ytKYhdFkMeta || {},
    alias: indexa4ytKYhdFkMeta?.alias || [],
    redirect: indexa4ytKYhdFkMeta?.redirect,
    component: () => import("~/pages/nft/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-de",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektionen/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-es-mx",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecciones/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-fr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-jp",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-pt-br",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecoes/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-ru",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektsii/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-tr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/koleksiyonlar/",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-de",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektionen/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-es-mx",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecciones/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-fr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-jp",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-pt-br",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecoes/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-ru",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektsii/blokcheyn/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-blockchain-tr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/koleksiyonlar/blockchain/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/art/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-de",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektionen/kunst/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-es-mx",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecciones/arte/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-fr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/art/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-jp",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/art/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-pt-br",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecoes/arte/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-ru",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektsii/iskusstvo/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-art-tr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/koleksiyonlar/sanat/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-de",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektionen/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-es-mx",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecciones/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-fr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-jp",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/collections/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-pt-br",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/colecoes/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-ru",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/kollektsii/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexIxOFaDkYYbMeta?.name ?? "nft-collections-utilities-tr",
    path: indexIxOFaDkYYbMeta?.path ?? "/nft/koleksiyonlar/utilities/:filterValue()",
    meta: indexIxOFaDkYYbMeta || {},
    alias: indexIxOFaDkYYbMeta?.alias || [],
    redirect: indexIxOFaDkYYbMeta?.redirect,
    component: () => import("~/pages/nft/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-de",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-es-mx",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-fr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-jp",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-pt-br",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-ru",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studii/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-tr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studyolar/",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-de",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-es-mx",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-fr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-jp",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-pt-br",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-ru",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studii/blokcheyn/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-blockchain-tr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studyolar/blockchain/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/art/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-de",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/kunst/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-es-mx",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/arte/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-fr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/art/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-jp",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/art/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-pt-br",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/arte/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-ru",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studii/iskusstvo/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-art-tr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studyolar/sanat/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-de",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-es-mx",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-fr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-jp",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studios/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-pt-br",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/estudios/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-ru",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studii/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: indext7JJSfHSuiMeta?.name ?? "nft-studios-utilities-tr",
    path: indext7JJSfHSuiMeta?.path ?? "/nft/studyolar/utilities/:filterValue()",
    meta: indext7JJSfHSuiMeta || {},
    alias: indext7JJSfHSuiMeta?.alias || [],
    redirect: indext7JJSfHSuiMeta?.redirect,
    component: () => import("~/pages/nft/studios/index.vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-de",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/events/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-es-mx",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/eventos/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-fr",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/evenements/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-jp",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/events/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-pt-br",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/eventos/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-ru",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/sobytiya/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91event_93etXCcdD4P4Meta?.name ?? "nft-event-tr",
    path: _91event_93etXCcdD4P4Meta?.path ?? "/nft/etkinlikler/:event",
    meta: _91event_93etXCcdD4P4Meta || {},
    alias: _91event_93etXCcdD4P4Meta?.alias || [],
    redirect: _91event_93etXCcdD4P4Meta?.redirect,
    component: () => import("~/pages/nft/events/[event].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-de",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/kollektionen/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-es-mx",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/colecciones/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-fr",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/collections/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-jp",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/collections/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-pt-br",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/colecoes/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-ru",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/kollektsii/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91collection_93dYNaxzt6BxMeta?.name ?? "nft-collections-collection-tr",
    path: _91collection_93dYNaxzt6BxMeta?.path ?? "/nft/koleksiyonlar/:collection",
    meta: _91collection_93dYNaxzt6BxMeta || {},
    alias: _91collection_93dYNaxzt6BxMeta?.alias || [],
    redirect: _91collection_93dYNaxzt6BxMeta?.redirect,
    component: () => import("~/pages/nft/collections/[collection].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-de",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/studios/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-es-mx",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/estudios/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-fr",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/studios/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-jp",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/studios/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-pt-br",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/estudios/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-ru",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/studii/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: _91studio_93RX2eR2y5QMMeta?.name ?? "nft-studio-tr",
    path: _91studio_93RX2eR2y5QMMeta?.path ?? "/nft/studyolar/:studio",
    meta: _91studio_93RX2eR2y5QMMeta || {},
    alias: _91studio_93RX2eR2y5QMMeta?.alias || [],
    redirect: _91studio_93RX2eR2y5QMMeta?.redirect,
    component: () => import("~/pages/nft/studios/[studio].vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-market-crypto",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/crypto/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-market-nft",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/nft/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-articles",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/articles/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-youtube",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/youtube/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-x-twitter",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/x-twitter/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-tiktok",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/tiktok/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-medium",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/medium/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "news-telegram",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/telegram/",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  }
]