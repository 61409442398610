import { defineNuxtPlugin } from '#app'
import * as vt from 'vue-toastification'
import '@/assets/styles/toast.scss'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(vt.default, {
        position: "top-right",
        draggable: false,
        closeOnClick: false,
    })
    return {
        provide: {
            toast: vt.useToast()
        }
    }
})
