<template>
	<svg
		width="250"
		height="128"
		class="error__image"
		viewBox="0 0 250 128"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M47.3903 114.618C46.3207 108.934 43.601 103.823 40.285 99.6729C38.8567 97.8858 37.2772 96.2254 35.4436 95.2583C34.4616 94.7397 33.3895 94.4054 32.576 93.516C31.0825 91.883 31.0032 89.0881 30.6457 86.6603C30.2292 83.8331 29.2182 81.0237 27.4825 79.1174C26.3776 77.9033 24.6794 76.472 23.1821 76.9363C19.0884 78.2052 19.9093 86.079 20.4533 90.1058C21.177 95.4626 22.0913 102.91 26.6475 105.095C27.1362 105.329 27.6532 105.528 28.0481 105.973C28.4708 106.45 28.6962 107.15 28.8187 107.842C29.3718 110.959 28.2677 114.68 29.995 117.057C30.8728 118.264 32.2389 118.678 33.1877 119.793C34.6928 121.563 34.7468 124.518 35.7865 126.758L47.4995 126.791C48.3981 123.343 48.0775 118.267 47.3903 114.618Z"
			fill="#F5B3A3" />
		<path
			d="M36.3985 115.153C32.6699 114.619 28.6468 115.671 25.2324 114.081C22.7847 112.941 21.1043 110.631 18.8916 109.083C16.6868 107.542 13.9392 106.793 11.2573 107.004C10.2782 107.08 9.26135 107.303 8.51935 107.946C7.22635 109.066 7.17902 111.043 7.42608 112.736C7.5685 113.71 7.78566 114.703 8.33749 115.519C9.90246 117.833 13.4131 117.849 15.4315 119.781C17.2294 121.501 17.403 124.242 18.1745 126.615H47.531C47.3085 124.688 46.6728 122.808 45.6426 121.164C43.6288 117.948 40.1542 115.689 36.3985 115.153Z"
			fill="white" />
		<path
			d="M18.4761 126.203H47.0537C46.7909 124.475 46.1847 122.82 45.2874 121.387C43.3313 118.263 39.985 116.088 36.3364 115.566C34.9704 115.371 33.5254 115.394 32.1298 115.417C29.7419 115.454 27.2738 115.494 25.0541 114.46C23.5269 113.749 22.2883 112.601 21.0904 111.491C20.3147 110.773 19.5125 110.03 18.6501 109.427C16.5387 107.95 13.856 107.22 11.2866 107.42C10.1422 107.51 9.34833 107.777 8.78819 108.263C7.84606 109.079 7.52509 110.565 7.83402 112.679C7.95941 113.537 8.15789 114.519 8.67816 115.289C9.46791 116.456 10.8278 116.998 12.2673 117.571C13.4877 118.057 14.7495 118.559 15.7157 119.484C17.1175 120.825 17.5859 122.72 18.0393 124.554C18.1743 125.101 18.3138 125.664 18.4761 126.203ZM47.9938 127.033H17.8699L17.7765 126.747C17.5647 126.095 17.3961 125.413 17.233 124.753C16.7887 122.956 16.3693 121.258 15.1415 120.084C14.2924 119.271 13.1065 118.799 11.9601 118.342C10.4669 117.748 8.92356 117.134 7.99056 115.754C7.37644 114.846 7.15139 113.75 7.01229 112.799C6.65977 110.386 7.07416 108.649 8.24426 107.635C8.9356 107.036 9.90971 106.695 11.2218 106.592C14.0191 106.373 16.826 107.138 19.1264 108.746C20.0353 109.382 20.8587 110.144 21.6551 110.883C22.8588 111.998 23.9953 113.051 25.4045 113.707C27.4516 114.66 29.7174 114.626 32.117 114.586C33.5457 114.563 35.0235 114.54 36.454 114.744C40.3417 115.299 43.9068 117.618 45.9916 120.946C47.0334 122.61 47.7073 124.555 47.9402 126.57L47.9938 127.033Z"
			fill="#0E0E2E" />
		<path
			d="M33.522 126.516C33.522 126.516 31.3076 121.395 25.2176 120.011C19.1279 118.626 13.4531 111.706 13.4531 111.706"
			fill="white" />
		<path
			d="M33.1419 126.682C33.1203 126.632 30.9271 121.734 25.1265 120.417C18.9683 119.017 13.3678 112.257 13.1328 111.97L13.7747 111.444C13.8308 111.512 19.4321 118.271 25.3104 119.606C31.5342 121.021 33.81 126.134 33.9038 126.352L33.1419 126.682Z"
			fill="#0E0E2E" />
		<path
			d="M225.217 126.463C225.316 126.499 225.783 122.783 225.8 122.571C226.335 115.889 224.381 109.623 219.912 105.837C218.798 104.895 213.846 100.914 212.924 103.664C211.304 108.491 218.444 123.888 225.217 126.463Z"
			fill="#F5B3A3" />
		<path
			d="M225.025 126.366C221.757 124.613 223.467 116.915 224.192 114.438C225.769 109.054 229.224 104.268 234.037 101.344C235.826 100.257 242.462 95.9696 243.91 99.3461C245.846 103.865 241.609 110.62 239.295 114.201C237.413 117.116 235.212 119.86 232.67 122.226C231.07 123.716 228.672 126.928 226.216 126.708C225.76 126.667 225.365 126.549 225.025 126.366Z"
			fill="white" />
		<path
			d="M225.222 125.999C225.521 126.16 225.869 126.259 226.253 126.293C228.008 126.448 229.836 124.546 231.303 123.015C231.689 122.613 232.053 122.232 232.387 121.921C234.762 119.711 236.969 117.038 238.947 113.975C240.886 110.972 245.428 103.942 243.528 99.5086C243.328 99.0406 243.006 98.7412 242.544 98.5947C240.409 97.9166 236.149 100.534 234.544 101.519L234.253 101.698C229.627 104.508 226.195 109.074 224.59 114.554C223.618 117.874 222.499 124.539 225.222 125.999ZM226.463 127.133C226.368 127.133 226.274 127.129 226.179 127.12C225.682 127.076 225.228 126.945 224.829 126.731V126.731C220.907 124.627 223.486 115.369 223.793 114.32C225.457 108.641 229.018 103.907 233.822 100.988L234.109 100.812C235.963 99.6742 240.304 97.0089 242.795 97.8037C243.491 98.0246 243.995 98.488 244.291 99.1818C246.365 104.021 241.826 111.048 239.644 114.426C237.629 117.545 235.378 120.272 232.953 122.529C232.637 122.824 232.28 123.196 231.902 123.59C230.383 125.176 228.508 127.133 226.463 127.133Z"
			fill="#0E0E2E" />
		<path
			d="M225.217 126.461C225.217 126.461 228.469 109.63 238.711 103.263L225.217 126.461Z"
			fill="white" />
		<path
			d="M225.623 126.543L224.809 126.386C224.816 126.343 225.654 122.078 227.739 117.093C229.672 112.471 233.108 106.26 238.491 102.913L238.93 103.619C228.957 109.818 225.656 126.376 225.623 126.543Z"
			fill="#0E0E2E" />
		<path
			d="M229.99 113.229C229.99 113.229 233.865 111.291 238.295 110.322L229.99 113.229Z"
			fill="white" />
		<path
			d="M230.176 113.598L229.805 112.854C229.844 112.835 233.778 110.883 238.206 109.914L238.384 110.725C234.055 111.672 230.214 113.579 230.176 113.598Z"
			fill="#0E0E2E" />
		<path d="M250 127.033H0V126.203H250V127.033Z" fill="#0E0E2E" />
		<path
			d="M57.648 64.0117C57.5297 64.0117 57.4181 63.9399 57.3729 63.823L56.7268 62.128C56.6683 61.9765 56.7441 61.8063 56.8956 61.7478C57.0472 61.6907 57.2173 61.7664 57.2758 61.918L57.9219 63.6129C57.9804 63.7645 57.9046 63.9346 57.7517 63.9918C57.7172 64.0051 57.6826 64.0117 57.648 64.0117Z"
			fill="#0E0E2E" />
		<path
			d="M59.9098 69.9434C59.7914 69.9434 59.6798 69.8716 59.6346 69.7546L58.9885 68.0596C58.93 67.9081 59.0058 67.7379 59.1573 67.6794C59.3089 67.621 59.479 67.6981 59.5375 67.8496L60.1836 69.5446C60.2421 69.6961 60.1663 69.8663 60.0134 69.9234C59.9802 69.9367 59.9443 69.9434 59.9098 69.9434Z"
			fill="#0E0E2E" />
		<path
			d="M54.6419 67.582C54.5236 67.582 54.4119 67.5102 54.3667 67.3933C54.3095 67.2404 54.3853 67.0716 54.5369 67.0131L56.2331 66.3683C56.3847 66.3112 56.5548 66.3869 56.612 66.5385C56.6692 66.6914 56.5934 66.8602 56.4418 66.9187L54.7469 67.5634C54.7123 67.5767 54.6764 67.582 54.6419 67.582Z"
			fill="#0E0E2E" />
		<path
			d="M60.5766 65.3223C60.4583 65.3223 60.3467 65.2505 60.3028 65.1322C60.2443 64.9806 60.3214 64.8105 60.473 64.7533L62.1679 64.1086C62.3194 64.0501 62.4896 64.1272 62.5481 64.2787C62.6053 64.4303 62.5295 64.6004 62.3766 64.6576L60.6817 65.3037C60.6471 65.3169 60.6125 65.3223 60.5766 65.3223Z"
			fill="#0E0E2E" />
		<path
			d="M201.507 106.905L149.209 13.2326C144.434 4.68223 132.157 4.68223 127.385 13.2326L75.0834 106.905C70.4221 115.256 76.4473 125.551 85.9968 125.551H190.597C200.146 125.551 206.171 115.256 201.507 106.905Z"
			fill="#F5B3A3" />
		<path
			d="M138.3 7.43176C133.933 7.43176 130.055 9.71383 127.922 13.5326L75.6205 107.205C73.5356 110.943 73.5842 115.378 75.7501 119.069C77.9107 122.748 81.7429 124.943 86.0019 124.943H190.602C194.861 124.943 198.693 122.748 200.853 119.069C203.019 115.378 203.068 110.943 200.98 107.205L148.682 13.5326C146.548 9.71383 142.667 7.43176 138.3 7.43176ZM190.602 126.164H86.0019C81.3054 126.164 77.0789 123.742 74.6996 119.685C72.3095 115.618 72.2582 110.73 74.5565 106.611L126.858 12.9384C129.21 8.72809 133.488 6.21376 138.3 6.21376C143.116 6.21376 147.394 8.72809 149.746 12.9384L202.044 106.611C204.345 110.73 204.291 115.618 201.904 119.685C199.525 123.742 195.298 126.164 190.602 126.164Z"
			fill="#0E0E2E" />
		<path
			d="M190.753 106.905L138.455 13.2326C133.68 4.68223 121.403 4.68223 116.631 13.2326L64.3295 106.905C59.6682 115.256 65.6934 125.551 75.2429 125.551H179.843C189.392 125.551 195.417 115.256 190.753 106.905Z"
			fill="white" />
		<path
			d="M127.543 7.43176C123.176 7.43176 119.297 9.71383 117.164 13.5326L64.8627 107.205C62.7778 110.943 62.8264 115.378 64.9923 119.069C67.1529 122.748 70.9851 124.943 75.2441 124.943H179.844C184.103 124.943 187.935 122.748 190.096 119.069C192.261 115.378 192.31 110.943 190.222 107.205L137.924 13.5326C135.79 9.71383 131.91 7.43176 127.543 7.43176ZM179.844 126.164H75.2441C70.5476 126.164 66.3211 123.742 63.9418 119.685C61.5517 115.618 61.5004 110.73 63.7987 106.611L116.1 12.9384C118.452 8.72809 122.73 6.21376 127.543 6.21376C132.358 6.21376 136.636 8.72809 138.988 12.9384L191.287 106.611C193.587 110.73 193.533 115.618 191.146 119.685C188.767 123.742 184.54 126.164 179.844 126.164Z"
			fill="#0E0E2E" />
		<path
			d="M90.1626 86.9453C90.0627 86.9453 89.9601 86.9183 89.8655 86.867C89.5712 86.7022 89.4658 86.3323 89.6306 86.0379L123.502 25.37C124.334 23.8819 125.844 22.9934 127.545 22.9934C129.247 22.9934 130.759 23.8819 131.588 25.37L135.215 31.8651C135.38 32.1595 135.275 32.5322 134.98 32.6942C134.686 32.8589 134.316 32.7536 134.151 32.4593L130.524 25.9642C129.914 24.8677 128.798 24.2141 127.545 24.2141C126.292 24.2141 125.18 24.8677 124.566 25.9642L90.6946 86.632C90.5839 86.8319 90.376 86.9453 90.1626 86.9453Z"
			fill="#0E0E2E" />
		<path
			d="M147.414 55.5801C147.201 55.5801 146.993 55.4667 146.882 55.2668L139.801 42.5845C139.639 42.2901 139.744 41.9201 140.036 41.7553C140.33 41.5933 140.703 41.696 140.865 41.9903L147.946 54.6727C148.111 54.967 148.005 55.337 147.711 55.5017C147.616 55.5531 147.517 55.5801 147.414 55.5801Z"
			fill="#0E0E2E" />
		<path
			d="M172.952 113.604H149.967C149.632 113.604 149.359 113.331 149.359 112.993C149.359 112.658 149.632 112.383 149.967 112.383H172.952C174.173 112.383 175.275 111.754 175.893 110.698C176.515 109.639 176.531 108.364 175.931 107.292C175.767 106.998 175.872 106.628 176.166 106.463C176.461 106.298 176.831 106.404 176.995 106.698C177.806 108.151 177.789 109.879 176.944 111.313C176.104 112.747 174.611 113.604 172.952 113.604Z"
			fill="#0E0E2E" />
		<path
			d="M140.333 113.604H82.1358C80.4749 113.604 78.9841 112.747 78.1415 111.313C77.2989 109.877 77.28 108.151 78.0929 106.698L84.1802 95.7926C84.3449 95.4982 84.7149 95.3929 85.0093 95.5577C85.3036 95.7224 85.409 96.0924 85.2442 96.3867L79.1569 107.292C78.5574 108.364 78.5709 109.639 79.1947 110.698C79.8132 111.754 80.9151 112.383 82.1358 112.383H140.333C140.67 112.383 140.943 112.658 140.943 112.993C140.943 113.331 140.67 113.604 140.333 113.604Z"
			fill="#0E0E2E" />
		<path
			d="M133.044 97.5456C133.044 100.705 130.487 103.266 127.332 103.266C124.181 103.266 121.623 100.705 121.623 97.5456C121.623 94.3859 124.181 91.8229 127.332 91.8229C130.487 91.8229 133.044 94.3859 133.044 97.5456Z"
			fill="#F5B3A3" />
		<path
			d="M128.317 49.5485H126.359C123.613 49.5485 121.414 51.8306 121.509 54.5799L122.489 82.7074C122.581 85.3243 124.725 87.3984 127.337 87.3984C129.951 87.3984 132.095 85.3243 132.187 82.7074L133.165 54.5799C133.262 51.8306 131.064 49.5485 128.317 49.5485Z"
			fill="#F5B3A3" />
		<path
			d="M174.821 25.1477C174.787 25.1353 174.755 25.1145 174.729 25.084C174.647 24.9849 174.657 24.8284 174.755 24.7349L191.83 8.15506C191.928 8.06152 192.073 8.06412 192.156 8.1636C192.24 8.26204 192.229 8.41819 192.132 8.51311L175.056 25.0915C174.989 25.1571 174.898 25.1752 174.821 25.1477Z"
			fill="#0E0E2E" />
		<path
			d="M178.838 28.7012C178.779 28.6797 178.728 28.631 178.703 28.5635C178.654 28.4352 178.716 28.283 178.838 28.2258L192.202 21.9497C192.325 21.8914 192.465 21.949 192.512 22.0779C192.56 22.2061 192.499 22.3573 192.376 22.4155L179.013 28.6906C178.955 28.7185 178.892 28.7204 178.838 28.7012Z"
			fill="#0E0E2E" />
		<path
			d="M173.159 21.3835C173.106 21.3645 173.059 21.3277 173.027 21.2757C171.893 19.4168 171.616 18.1027 172.18 17.2564C172.62 16.5964 173.398 16.5099 174.149 16.4256C174.877 16.3444 175.564 16.2671 176.012 15.7273C176.549 15.0811 176.354 14.2097 176.149 13.2881C175.928 12.297 175.699 11.2731 176.373 10.4427C176.968 9.7083 177.882 9.63371 178.689 9.56922C179.481 9.50428 180.166 9.44943 180.534 8.86686C180.847 8.37226 180.743 7.79343 180.635 7.18153C180.505 6.4494 180.372 5.69394 180.953 5.0509C181.257 4.71579 181.601 4.59191 181.904 4.48372C182.214 4.3723 182.46 4.28411 182.651 3.99426C182.952 3.53705 182.927 2.8509 182.58 1.95837C182.53 1.83031 182.595 1.68891 182.725 1.6428C182.854 1.59631 183.001 1.66263 183.05 1.79069C183.458 2.8383 183.467 3.67088 183.076 4.26626C182.797 4.69156 182.419 4.82752 182.086 4.94687C181.813 5.0439 181.556 5.13625 181.333 5.38109C180.909 5.84962 181.012 6.43293 181.132 7.10687C181.245 7.75082 181.375 8.48051 180.964 9.13138C180.466 9.92016 179.589 9.99074 178.74 10.0598C177.965 10.1228 177.233 10.1817 176.77 10.7533C176.244 11.4033 176.437 12.2719 176.642 13.1921C176.864 14.1871 177.093 15.2158 176.407 16.0416C175.832 16.7334 174.975 16.8295 174.217 16.9149C173.52 16.9923 172.918 17.0595 172.604 17.5315C172.161 18.1962 172.448 19.3734 173.458 21.0302C173.531 21.1479 173.492 21.2988 173.373 21.3661C173.306 21.4038 173.228 21.4083 173.159 21.3835Z"
			fill="#0E0E2E" />
	</svg>
	<h1 class="error__title">Oops! Something went wrong</h1>
	<p class="error__text">Unknown error occurred, please contact support</p>
</template>

<script>
export default {
	name: 'Errors',
};
</script>

<style>
.error__image {
	margin-bottom: 42px;
}
.error__title {
	margin-bottom: 8px;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
}
.error__text {
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
}
</style>
