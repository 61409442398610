<template>
    <header class="header">
        <div class="header__container">
            <NuxtLink to="/nft/" class="header-logo">
                <picture>
                    <source media="(max-width:767px)" srcset="~/assets/images/svg/logo-color.svg">
                    <img src="~/assets/images/svg/logo-lg.svg" alt="DOXXY" class="img img--contain">
                </picture>
            </NuxtLink>
    <!--        {{locale}}<br>-->
<!--			<button @click="throwError">test Sentry</button>-->
            <Navigation/>
    <!--        <nav class="header-nav-list">-->
    <!--            <div class="header-nav body-1"><a href="/nft/events" class="white_a">{{$t('events')}}</a>-->
    <!--            </div>-->
    <!--            <div class="header-nav body-1"><a href="/nft/collections" class="white_a">{{$t('nftCollections')}}</a>-->
    <!--            </div>-->
    <!--            <div class="header-nav body-1"><a href="/nft/studios" class="white_a">{{$t('studios')}}</a>-->
    <!--            </div>-->
    <!--            <i class="icon-plus header-menu-close"></i>-->
    <!--        </nav>-->

            <div class="btn-wrapper" @click="menuOpener">
    <!--            {% if DEBUG %}-->
    <!--            {% if request.user.is_authenticated %}-->
    <!--            <div id="header-controller" class="header-control" onclick="openNotifications(event)">-->
    <!--                <div id="notification-bell" class="header-control__icon icon-bell">-->
    <!--                    {% if request.user|count_unread_notification > 0 %}-->
    <!--                    <span class="header-control__icon-count">{{ request.user|count_unread_notification }}</span>-->
    <!--                    {% endif %}-->
    <!--                </div>-->
    <!--                <div class="header-control__menu header-control__notification">-->
    <!--                    {% csrf_token %}-->
    <!--                    <div class="header-control__notification-headline">-->
    <!--                        <h2 class="headline-2">Notifications</h2>-->
    <!--                        <span class="link body-1" onclick="markAllRead()">Mark all as read</span>-->
    <!--                    </div>-->

    <!--                    <div id="notifications-body" class="header-control__notification-body">-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->


    <!--            <div id="profile-menu-btn" class="header-control" onclick="openProfileMenu(event)">-->
    <!--                <i class="header-control__icon icon-account"></i>-->
    <!--                <div class="header-control__menu">-->
    <!--                    <a href="{% url 'auth:user-profile' pk=request.user.uuid %}"-->
    <!--                       class="header-control__link icon-account body-1">Profile</a>-->
    <!--                    <a href="{% url 'core:user-dashboard' %}"-->
    <!--                       class="header-control__link icon-dashboard body-1">Dashboard</a>-->
    <!--                    <a href="{% url 'auth:user-settings' %}"-->
    <!--                       class="header-control__link icon-settings body-1">Settings</a>-->
    <!--                    <a href="{% url 'auth:logout' %}" class="header-control__link icon-logout body-1">Log-->
    <!--                        out</a>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            {% endif %}-->
    <!--            {% endif %}-->
                <i class="icon-menu header-menu"></i>
            </div>
        </div>
    </header>
</template>

<script>
import {useStore} from '~/stores/store';

export default {
	name: 'Header',
    setup() {
        const store = useStore();
        const {locale} = useI18n();

        return {store, locale}
    },
	data() {
		return {
            isShowModalMenuMobile: false,
        };
	},
    methods: {
        menuOpener() {
            this.store.isOpenMobileMenu = !this.store.isOpenMobileMenu;
        },
        // throwError() {
        //     this.$sentry.captureMessage('test');
		//
        //     // throw new Error('Sentry Error');
        // }
    },
};
</script>

<style lang="scss" scoped>
.header{
    //width: 100%;
    //display: flex;
    //justify-content: space-between;
    //box-sizing: border-box;
    //padding: 2.4rem 0;
    //gap: 2rem;
    //align-items: center;

    //&-logo{
    //    height: 4.4rem;
    //    -webkit-touch-callout: none;
    //    touch-action: none;
    //    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    //    -webkit-user-select: none;
    //    user-select: none;
    //}
    //&-nav{
    //    display: inline-flex;
    //    align-items: center;
    //    white-space: nowrap;
    //    gap: .4rem;
    //    color: $white;
    //    cursor: pointer;
    //    position: relative;
    //    -webkit-touch-callout: none;
    //    touch-action: none;
    //    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    //    -webkit-user-select: none;
    //    user-select: none;
	//
    //    &:not(.icon-arrow-down-2):hover{
    //        color: $link;
    //    }
    //    &:hover{
    //        .header-nav__menu{
    //            @media (min-width: 991px){
    //                pointer-events: auto;
    //                opacity: 1;
    //            }
    //            @media (max-width: 990px) {
    //                height: auto;
    //            }
    //        }
    //    }
    //    &:before{
    //        width: 2.4rem;
    //        min-width: 2.4rem;
    //        height: 2.4rem;
    //        font-size: 2.4rem;
    //        order: 1;
    //        display: inline-flex;
    //        align-items: center;
    //        justify-content: center;
    //    }
    //    @media (max-width: 990px) {
    //        font-size: 1.6rem;
    //        flex-wrap: wrap;
    //    }
    //    &__menu{
    //        width: 100%;
    //        order: 1;
    //        @media (min-width: 991px) {
    //            opacity: 0;
    //            pointer-events: none;
    //            min-width: 12rem;
    //            width: 100%;
    //            position: absolute;
    //            left: 0;
    //            top: 100%;
    //            border: .1rem solid $dark-2;
    //            background-color: $dark;
    //            z-index: 5;
    //            transition: opacity .2s linear;
    //            padding: .6rem 0;
    //        }
    //        @media (max-width: 990px) {
    //            overflow: hidden;
    //            height: 0;
    //        }
    //    }
    //    &__option{
    //        color: $white;
    //        display: block;
    //        line-height: 1.2;
    //        cursor: pointer;
    //        padding: .4rem 1.2rem;
    //        transition: background-color .2s linear;
    //        &:hover{
    //            color: $link;
    //        }
    //    }
    //}

    &-menu, &-menu-close{
        height: 3.2rem;
        width: 3.2rem;
        min-width: 3.2rem;
        font-size: 3.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        cursor: pointer;
        @media (min-width: 991px) {
            display: none;
        }
    }
    &-menu-close{
        position: absolute;
        top: 3.2rem;
        right: 1.2rem;
        &:before{
            transform: rotate(45deg);
        }
    }

    &-control{
        position: relative;
        &.open{
            .header-control__icon{
                border-color: $link;
                background-color: $link;
            }
            .header-control__menu{
                display: block;
            }
            .header-control__icon-count{
                border-color: $link;
            }
        }
        &__icon, &-icon{
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 3.8rem;
            min-width: 3.8rem;
            height: 3.8rem;
            font-size: 2.4rem;
            border-radius: 50%;
            box-sizing: border-box;
            border: .1rem solid $dark-2;
            background-color: rgba($black, .62);
            transition: border-color .2s linear, background-color .2s linear;
            -webkit-touch-callout: none;
            touch-action: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-user-select: none;
            user-select: none;
            &:hover{
                border-color: $link;
            }
            &-count{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: .9rem;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                background-color: $red;
                border: .1rem solid rgba($black, .62);
                position: absolute;
                top: .6rem;
                right: .6rem;
            }
        }
        &__menu{
            display: none;
            position: absolute;
            top: calc(100% + 1.2rem);
            right: 0;
            box-sizing: border-box;
            padding: 1.8rem 0;
            border: .1rem solid $dark-2;
            background-color: $dark-7;
            z-index: 99;

        }
        &__notification{
            padding: 0;
            right: -6.2rem;
            max-width: calc(100vw - 4rem);
            width: 37.6rem;
            @media (max-width: 500px) {
                right: -11rem;
            }
            &-headline{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2.4rem;
                box-sizing: border-box;
                width: 100%;
            }
            &-body{
                overflow: auto;
                height: min(49.6rem, 80vh);
                max-height: min(49.6rem, 80vh);
                display: flex;
                flex-direction: column;
            }
        }
        &__card{
            display: flex;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            padding: 2.4rem;
            gap: 1.2rem;
            border-top: .1rem solid $dark-2;
            transition: background-color .2s linear;
            &.read{
                background-color: $black-2;
                .header-control__card-time:before{
                    border-color: $cyan;
                    background-color: $cyan;
                }
            }
            &-headline{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                gap: 1.8rem;
            }
            &-title{
                display: inline-flex;
                align-items: center;
                gap: .4rem;
            }
            &-icon{
                width: 2.4rem;
                min-width: 2.4rem;
                height: 2.4rem;
                font-size: 2.4rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-weight: $regular;
            }
            &-time{
                color: rgba($white, .25);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: .8rem;
                &:before{
                    content: '';
                    display: inline-block;
                    width: .8rem;
                    min-width: .8rem;
                    height: .8rem;
                    box-sizing: border-box;
                    border: .1rem solid rgba($white, .1);
                    transition: all .2s linear;
                }
            }
        }
        &__link{
            display: flex;
            align-items: center;
            gap: 1.2rem;
            width: 100%;
            box-sizing: border-box;
            padding: .5rem 2.4rem;
            transition: background-color .2s linear;
            color: $white;
            cursor: pointer;
            &:before{
                width: 2.4rem;
                height: 2.4rem;
                min-width: 2.4rem;
                font-size: 2.4rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            &:hover{
                color: $white;
                background-color: $link;
            }
        }

        &__btn-wrapper{
            padding: 2.4rem;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            border-top: 0.1rem solid $dark-2;
        }

        &__empty{
            border-top: .1rem solid $dark-2;
            box-sizing: border-box;
            background-color: $black-3;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 1.8rem;
            align-items: center;
            justify-content: center;
            &-image{
                width: 8.8rem;
                min-width: 8.8rem;
                height: 8.8rem;
                font-size: 8.8rem;
                display: inline-flex;
                align-items: center;
                background-color: $dark-8;
                color: $dark-9;
            }
            &-text{
                display: flex;
                flex-direction: column;
                gap: .8rem;
                align-items: center;
                justify-content: center;
                text-align: center;
                opacity: .25;
                max-width: 17.6rem;
            }
        }
    }
}
</style>
