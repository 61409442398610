import fetchWrapper from '~/api/fetchWrapper';

export default (app, options) => ({
    async fetchEntities() {
        // console.debug('API entities.fetchEntities', app.$i18n.locale.value);
        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NFT_URL + `/events/entities/`,{
            method: 'GET',
        });
		// console.log(response)
        return response;
    },
});
