import { useStore } from '@/stores/store';
// import {createError} from 'nuxt/app';
// import {useNuxtApp} from '#app';
import { md5 } from 'js-md5';
import crypto from "crypto";

function generateMD5Hash(payload) {
    let data = 'xbXFICOkW!^wRxR%vKV3uAF5iRR@f9dt_' + new Date().toISOString().substring(0, 13);

    if (payload) {
        data += `_${JSON.stringify(payload, null, 0)}`;
    }

    return md5(data);
}

export default async (app, url, options) => {
    try {
        const store = useStore();
        const nuxtApp = useNuxtApp();
        const { locale } = nuxtApp.$i18n;
        const token = generateMD5Hash(options.body)

        options = {
            headers: {
                'Language': locale.value,
                'X-API-KEY': token
            },
            ...options,
        }
        return await $fetch(url, options);
    } catch (err) {
		// console.log('err', err)
        if (err?.data?.message) {
            app.$toast.error(`${err.status}: ${err.data.message}`);
        } else if (err?.message) {
            app.$toast.error(err.message);
        } else {
			app.$toast.error(err);
		}

        throw err;
    }
};
