import entities from '~/api/entities';
import events from '~/api/events';
import collections from '~/api/collections';
import studios from '~/api/studios';
import news from '~/api/news';
import {useStore} from '~/stores/store';
import {useNuxtApp} from '#app';

export const useApi = (url: string, options: object) => {
    const app = useNuxtApp();
    const store = useStore();
    // const user = useUser();
    let requestOptions = {};

    // console.log('api composable', userStore.token);

    // if (user.token) {
    //     requestOptions = {
    //         token: user.token
    //     }
    // }

    // if (user.token) {
    //     requestOptions = {
    //         token: user.token
    //     }
    // }

    return {
        entities: entities(app, requestOptions),
        events: events(app, requestOptions),
        collections: collections(app, requestOptions),
        studios: studios(app, requestOptions),
        news: news(app, requestOptions),
    };
}
