import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()

    Sentry.init({
        enabled: config.public.SENTRY_ENABLED,
        app: nuxtApp.vueApp,
        autoSessionTracking: true,
        debug: false,
        // debug: config.public.ENV !== 'production',
        dsn: config.public.SENTRY_DSN,
        // release: config.public.SENTRY_RELEASE,
        environment: config.public.SENTRY_ENVIRONMENT,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
                // tracePropagationTargets: [config.public.SENTRY_TRACE_PROPAGATION_TARGET],
            }),
            new Sentry.Replay(),
        ],
        // attachScreenshot: true,
        // trackComponents: true,
        // hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    })

    return {
        provide: {
            sentry: {
                withScope: Sentry.withScope,
                configureScope: Sentry.configureScope,
                setContext: Sentry.setContext,
                setUser: Sentry.setUser,
                setTag: Sentry.setTag,
                setExtra: Sentry.setExtra,
                addBreadcrumb: Sentry.addBreadcrumb,
                captureException: Sentry.captureException,
                captureMessage: Sentry.captureMessage,
            }
        },
    }
})
