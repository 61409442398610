
// @ts-nocheck
import locale____w_doxxy_frontend_doxxy_frontend_lang_en_json from "../lang/en.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_ru_json from "../lang/ru.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_de_json from "../lang/de.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_es_mx_json from "../lang/es-mx.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_fr_json from "../lang/fr.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_ja_json from "../lang/ja.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_pt_br_json from "../lang/pt-br.json";
import locale____w_doxxy_frontend_doxxy_frontend_lang_tr_json from "../lang/tr.json";


export const localeCodes =  [
  "en",
  "ru",
  "de",
  "es-mx",
  "fr",
  "ja",
  "pt-br",
  "tr"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_en_json), cache: true }],
  "ru": [{ key: "../lang/ru.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_ru_json), cache: true }],
  "de": [{ key: "../lang/de.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_de_json), cache: true }],
  "es-mx": [{ key: "../lang/es-mx.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_es_mx_json), cache: true }],
  "fr": [{ key: "../lang/fr.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_fr_json), cache: true }],
  "ja": [{ key: "../lang/ja.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_ja_json), cache: true }],
  "pt-br": [{ key: "../lang/pt-br.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_pt_br_json), cache: true }],
  "tr": [{ key: "../lang/tr.json", load: () => Promise.resolve(locale____w_doxxy_frontend_doxxy_frontend_lang_tr_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "lang/en.json"
      ]
    },
    {
      "code": "ru",
      "name": "Russian",
      "files": [
        "lang/ru.json"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "lang/de.json"
      ]
    },
    {
      "code": "es-mx",
      "name": "Spanish",
      "files": [
        "lang/es-mx.json"
      ]
    },
    {
      "code": "fr",
      "name": "French",
      "files": [
        "lang/fr.json"
      ]
    },
    {
      "code": "ja",
      "name": "Japanese",
      "files": [
        "lang/ja.json"
      ]
    },
    {
      "code": "pt-br",
      "name": "Brazilian Portuguese",
      "files": [
        "lang/pt-br.json"
      ]
    },
    {
      "code": "tr",
      "name": "Turkish",
      "files": [
        "lang/tr.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "silentTranslationWarn": true,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "lang/en.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Russian",
    "files": [
      {
        "path": "lang/ru.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "lang/de.json"
      }
    ]
  },
  {
    "code": "es-mx",
    "name": "Spanish",
    "files": [
      {
        "path": "lang/es-mx.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "French",
    "files": [
      {
        "path": "lang/fr.json"
      }
    ]
  },
  {
    "code": "ja",
    "name": "Japanese",
    "files": [
      {
        "path": "lang/ja.json"
      }
    ]
  },
  {
    "code": "pt-br",
    "name": "Brazilian Portuguese",
    "files": [
      {
        "path": "lang/pt-br.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Turkish",
    "files": [
      {
        "path": "lang/tr.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
