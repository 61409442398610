import fetchWrapper from '~/api/fetchWrapper';

export default (app, params) => ({
    async fetchEvents(params) {
        // console.debug('API events.fetchEvents');
		const data = {};

		if (params?.page) {
			data.page = params.page;
		}

		if (params?.filterId?.length) {
			data.filter_id = params.filterId;
		}

		if (params?.calendar?.length) {
			// data.calendar = params.calendar;
			data.calendar = {
				start: params.calendar[0],
				end: params.calendar[1]
			};
		}

		if (params?.search?.length) {
			data.search = params.search;
		}

		if (params?.approves?.length) {
			data.approves = params.approves;
		}

		if (params?.event_types?.length) {
			data.event_types = params.event_types;
		}

		if (params?.blockchains?.length) {
			data.blockchains = params.blockchains;
		}

		if (params?.arts?.length) {
			data.arts = params.arts;
		}

		if (params?.utilities?.length) {
			data.utilities = params.utilities;
		}

		if (params?.studios?.length) {
			data.studios = params.studios;
		}

		if (params?.nft_collections?.length) {
			data.nft_collections = params.nft_collections;
		}

		if (params?.complaints) {
			data.complaints = params.complaints;
		}

		if (params?.feedback) {
			data.feedback = params.feedback;
		}

		if (params?.sort_by) {
			data.sort_by = params.sort_by;
		}

		if (params?.order_by) {
			data.order_by = params.order_by;
		}

        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NFT_URL + `/events/`,{
            method: 'POST',
            body: data,
        });

        // if (response) {
        //     return response;
        // }

        return response;
    },
	async fetchEventBySlug(slug) {
		// console.debug('API events.fetchEventBySlug');
		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NFT_URL + `/events/${slug}/`,{
			method: 'GET',
			params
		});
		// console.log('response', response)
		return response;
	},
	async fetchRecentEvents(params = {}) {
		// console.debug('API events.fetchRecentEvents');
		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NFT_URL + `/events/recent/`,{
			method: 'GET',
			params
		});

		return response;
	},
	async fetchRecommendedEvents(data = {}) {
		// console.debug('API events.fetchRecentEvents');
		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NFT_URL + `/events/recommended/`,{
			method: 'POST',
			body: data,
		});

		return response;
	},
});
