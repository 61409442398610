export default {
	'nft-events': {
		file: '~/pages/nft/events/index.vue',
		children: {
			'index': {
				'en': {
					name: 'nft-events',
					path: '/nft/events/',
				},
				'de': {
					name: 'nft-events-de',
					path: '/nft/events/',
				},
				'es-mx': {
					name: 'nft-events-es-mx',
					path: '/nft/eventos/',
				},
				'fr': {
					name: 'nft-events-fr',
					path: '/nft/evenements/',
				},
				'ja': {
					name: 'nft-events-jp',
					path: '/nft/events/',
				},
				'pt-br': {
					name: 'nft-events-pt-br',
					path: '/nft/eventos/',
				},
				'ru': {
					name: 'nft-events-ru',
					path: '/nft/sobytiya/',
				},
				'tr': {
					name: 'nft-events-tr',
					path: '/nft/etkinlikler/',
				},
			},
			'event-type': {
				'en': {
					name: 'nft-events-event-type',
					path: '/nft/events/event-type/:filterValue()',
				},
				'de': {
					name: 'nft-events-event-type-de',
					path: '/nft/events/event-type/:filterValue()',
				},
				'es-mx': {
					name: 'nft-events-event-type-es-mx',
					path: '/nft/eventos/tipo-de-evento/:filterValue()',
				},
				'fr': {
					name: 'nft-events-event-type-fr',
					path: '/nft/evenements/type-devenement/:filterValue()',
				},
				'ja': {
					name: 'nft-events-event-type-jp',
					path: '/nft/events/event-type/:filterValue()',
				},
				'pt-br': {
					name: 'nft-events-event-type-pt-br',
					path: '/nft/eventos/tipo-de-evento/:filterValue()',
				},
				'ru': {
					name: 'nft-events-event-type-ru',
					path: '/nft/sobytiya/tip-sobytiya/:filterValue()',
				},
				'tr': {
					name: 'nft-events-event-type-tr',
					path: '/nft/etkinlikler/etkinlik-turu/:filterValue()',
				},
			},
			'blockchain': {
				'en': {
					name: 'nft-events-blockchain',
					path: '/nft/events/blockchain/:filterValue()',
				},
				'de': {
					name: 'nft-events-blockchain-de',
					path: '/nft/events/blockchain/:filterValue()',
				},
				'es-mx': {
					name: 'nft-events-blockchain-es-mx',
					path: '/nft/eventos/blockchain/:filterValue()',
				},
				'fr': {
					name: 'nft-events-blockchain-fr',
					path: '/nft/evenements/blockchain/:filterValue()',
				},
				'ja': {
					name: 'nft-events-blockchain-jp',
					path: '/nft/events/blockchain/:filterValue()',
				},
				'pt-br': {
					name: 'nft-events-blockchain-pt-br',
					path: '/nft/eventos/blockchain/:filterValue()',
				},
				'ru': {
					name: 'nft-events-blockchain-ru',
					path: '/nft/sobytiya/blokcheyn/:filterValue()',
				},
				'tr': {
					name: 'nft-events-blockchain-tr',
					path: '/nft/etkinlikler/blockchain/:filterValue()',
				},
			},
			'art': {
				'en': {
					name: 'nft-events-art',
					path: '/nft/events/art/:filterValue()',
				},
				'de': {
					name: 'nft-events-art-de',
					path: '/nft/events/kunst/:filterValue()',
				},
				'es-mx': {
					name: 'nft-events-art-es-mx',
					path: '/nft/eventos/arte/:filterValue()',
				},
				'fr': {
					name: 'nft-events-art-fr',
					path: '/nft/evenements/art/:filterValue()',
				},
				'ja': {
					name: 'nft-events-art-jp',
					path: '/nft/events/art/:filterValue()',
				},
				'pt-br': {
					name: 'nft-events-art-pt-br',
					path: '/nft/eventos/arte/:filterValue()',
				},
				'ru': {
					name: 'nft-events-art-ru',
					path: '/nft/sobytiya/iskusstvo/:filterValue()',
				},
				'tr': {
					name: 'nft-events-art-tr',
					path: '/nft/etkinlikler/sanat/:filterValue()',
				},
			},
			'utilities': {
				'en': {
					name: 'nft-events-utilities',
					path: '/nft/events/utilities/:filterValue()',
				},
				'de': {
					name: 'nft-events-utilities-de',
					path: '/nft/events/utilities/:filterValue()',
				},
				'es-mx': {
					name: 'nft-events-utilities-es-mx',
					path: '/nft/eventos/utilities/:filterValue()',
				},
				'fr': {
					name: 'nft-events-utilities-fr',
					path: '/nft/evenements/utilities/:filterValue()',
				},
				'ja': {
					name: 'nft-events-utilities-jp',
					path: '/nft/events/utilities/:filterValue()',
				},
				'pt-br': {
					name: 'nft-events-utilities-pt-br',
					path: '/nft/eventos/utilities/:filterValue()',
				},
				'ru': {
					name: 'nft-events-utilities-ru',
					path: '/nft/sobytiya/utilities/:filterValue()',
				},
				'tr': {
					name: 'nft-events-utilities-tr',
					path: '/nft/etkinlikler/utilities/:filterValue()',
				},
			},
		}
	},
	'nft-collections': {
		file: '~/pages/nft/collections/index.vue',
		children: {
			'index': {
				'en': {
					name: 'nft-collections',
					path: '/nft/collections/',
				},
				'de': {
					name: 'nft-collections-de',
					path: '/nft/kollektionen/',
				},
				'es-mx': {
					name: 'nft-collections-es-mx',
					path: '/nft/colecciones/',
				},
				'fr': {
					name: 'nft-collections-fr',
					path: '/nft/collections/',
				},
				'ja': {
					name: 'nft-collections-jp',
					path: '/nft/collections/',
				},
				'pt-br': {
					name: 'nft-collections-pt-br',
					path: '/nft/colecoes/',
				},
				'ru': {
					name: 'nft-collections-ru',
					path: '/nft/kollektsii/',
				},
				'tr': {
					name: 'nft-collections-tr',
					path: '/nft/koleksiyonlar/',
				},
			},
			'blockchain': {
				'en': {
					name: 'nft-collections-blockchain',
					path: '/nft/collections/blockchain/:filterValue()',
				},
				'de': {
					name: 'nft-collections-blockchain-de',
					path: '/nft/kollektionen/blockchain/:filterValue()',
				},
				'es-mx': {
					name: 'nft-collections-blockchain-es-mx',
					path: '/nft/colecciones/blockchain/:filterValue()',
				},
				'fr': {
					name: 'nft-collections-blockchain-fr',
					path: '/nft/collections/blockchain/:filterValue()',
				},
				'ja': {
					name: 'nft-collections-blockchain-jp',
					path: '/nft/collections/blockchain/:filterValue()',
				},
				'pt-br': {
					name: 'nft-collections-blockchain-pt-br',
					path: '/nft/colecoes/blockchain/:filterValue()',
				},
				'ru': {
					name: 'nft-collections-blockchain-ru',
					path: '/nft/kollektsii/blokcheyn/:filterValue()',
				},
				'tr': {
					name: 'nft-collections-blockchain-tr',
					path: '/nft/koleksiyonlar/blockchain/:filterValue()',
				},
			},
			'art': {
				'en': {
					name: 'nft-collections-art',
					path: '/nft/collections/art/:filterValue()',
				},
				'de': {
					name: 'nft-collections-art-de',
					path: '/nft/kollektionen/kunst/:filterValue()',
				},
				'es-mx': {
					name: 'nft-collections-art-es-mx',
					path: '/nft/colecciones/arte/:filterValue()',
				},
				'fr': {
					name: 'nft-collections-art-fr',
					path: '/nft/collections/art/:filterValue()',
				},
				'ja': {
					name: 'nft-collections-art-jp',
					path: '/nft/collections/art/:filterValue()',
				},
				'pt-br': {
					name: 'nft-collections-art-pt-br',
					path: '/nft/colecoes/arte/:filterValue()',
				},
				'ru': {
					name: 'nft-collections-art-ru',
					path: '/nft/kollektsii/iskusstvo/:filterValue()',
				},
				'tr': {
					name: 'nft-collections-art-tr',
					path: '/nft/koleksiyonlar/sanat/:filterValue()',
				},
			},
			'utilities': {
				'en': {
					name: 'nft-collections-utilities',
					path: '/nft/collections/utilities/:filterValue()',
				},
				'de': {
					name: 'nft-collections-utilities-de',
					path: '/nft/kollektionen/utilities/:filterValue()',
				},
				'es-mx': {
					name: 'nft-collections-utilities-es-mx',
					path: '/nft/colecciones/utilities/:filterValue()',
				},
				'fr': {
					name: 'nft-collections-utilities-fr',
					path: '/nft/collections/utilities/:filterValue()',
				},
				'ja': {
					name: 'nft-collections-utilities-jp',
					path: '/nft/collections/utilities/:filterValue()',
				},
				'pt-br': {
					name: 'nft-collections-utilities-pt-br',
					path: '/nft/colecoes/utilities/:filterValue()',
				},
				'ru': {
					name: 'nft-collections-utilities-ru',
					path: '/nft/kollektsii/utilities/:filterValue()',
				},
				'tr': {
					name: 'nft-collections-utilities-tr',
					path: '/nft/koleksiyonlar/utilities/:filterValue()',
				},
			},
		}
	},
	'nft-studios': {
		file: '~/pages/nft/studios/index.vue',
		children: {
			'index': {
				'en': {
					name: 'nft-studios',
					path: '/nft/studios/',
				},
				'de': {
					name: 'nft-studios-de',
					path: '/nft/studios/',
				},
				'es-mx': {
					name: 'nft-studios-es-mx',
					path: '/nft/estudios/',
				},
				'fr': {
					name: 'nft-studios-fr',
					path: '/nft/studios/',
				},
				'ja': {
					name: 'nft-studios-jp',
					path: '/nft/studios/',
				},
				'pt-br': {
					name: 'nft-studios-pt-br',
					path: '/nft/estudios/',
				},
				'ru': {
					name: 'nft-studios-ru',
					path: '/nft/studii/',
				},
				'tr': {
					name: 'nft-studios-tr',
					path: '/nft/studyolar/',
				},
			},
			'blockchain': {
				'en': {
					name: 'nft-studios-blockchain',
					path: '/nft/studios/blockchain/:filterValue()',
				},
				'de': {
					name: 'nft-studios-blockchain-de',
					path: '/nft/studios/blockchain/:filterValue()',
				},
				'es-mx': {
					name: 'nft-studios-blockchain-es-mx',
					path: '/nft/estudios/blockchain/:filterValue()',
				},
				'fr': {
					name: 'nft-studios-blockchain-fr',
					path: '/nft/studios/blockchain/:filterValue()',
				},
				'ja': {
					name: 'nft-studios-blockchain-jp',
					path: '/nft/studios/blockchain/:filterValue()',
				},
				'pt-br': {
					name: 'nft-studios-blockchain-pt-br',
					path: '/nft/estudios/blockchain/:filterValue()',
				},
				'ru': {
					name: 'nft-studios-blockchain-ru',
					path: '/nft/studii/blokcheyn/:filterValue()',
				},
				'tr': {
					name: 'nft-studios-blockchain-tr',
					path: '/nft/studyolar/blockchain/:filterValue()',
				},
			},
			'art': {
				'en': {
					name: 'nft-studios-art',
					path: '/nft/studios/art/:filterValue()',
				},
				'de': {
					name: 'nft-studios-art-de',
					path: '/nft/studios/kunst/:filterValue()',
				},
				'es-mx': {
					name: 'nft-studios-art-es-mx',
					path: '/nft/estudios/arte/:filterValue()',
				},
				'fr': {
					name: 'nft-studios-art-fr',
					path: '/nft/studios/art/:filterValue()',
				},
				'ja': {
					name: 'nft-studios-art-jp',
					path: '/nft/studios/art/:filterValue()',
				},
				'pt-br': {
					name: 'nft-studios-art-pt-br',
					path: '/nft/estudios/arte/:filterValue()',
				},
				'ru': {
					name: 'nft-studios-art-ru',
					path: '/nft/studii/iskusstvo/:filterValue()',
				},
				'tr': {
					name: 'nft-studios-art-tr',
					path: '/nft/studyolar/sanat/:filterValue()',
				},
			},
			'utilities': {
				'en': {
					name: 'nft-studios-utilities',
					path: '/nft/studios/utilities/:filterValue()',
				},
				'de': {
					name: 'nft-studios-utilities-de',
					path: '/nft/studios/utilities/:filterValue()',
				},
				'es-mx': {
					name: 'nft-studios-utilities-es-mx',
					path: '/nft/estudios/utilities/:filterValue()',
				},
				'fr': {
					name: 'nft-studios-utilities-fr',
					path: '/nft/studios/utilities/:filterValue()',
				},
				'ja': {
					name: 'nft-studios-utilities-jp',
					path: '/nft/studios/utilities/:filterValue()',
				},
				'pt-br': {
					name: 'nft-studios-utilities-pt-br',
					path: '/nft/estudios/utilities/:filterValue()',
				},
				'ru': {
					name: 'nft-studios-utilities-ru',
					path: '/nft/studii/utilities/:filterValue()',
				},
				'tr': {
					name: 'nft-studios-utilities-tr',
					path: '/nft/studyolar/utilities/:filterValue()',
				},
			},
		}
	},
	'nft-event': {
		file: '~/pages/nft/events/[event].vue',
		children: {
			'index': {
				// 'en': {
				// 	name: 'nft-event',
				// 	path: '/nft/events/:event',
				// },
				'de': {
					name: 'nft-event-de',
					path: '/nft/events/:event',
				},
				'es-mx': {
					name: 'nft-event-es-mx',
					path: '/nft/eventos/:event',
				},
				'fr': {
					name: 'nft-event-fr',
					path: '/nft/evenements/:event',
				},
				'ja': {
					name: 'nft-event-jp',
					path: '/nft/events/:event',
				},
				'pt-br': {
					name: 'nft-event-pt-br',
					path: '/nft/eventos/:event',
				},
				'ru': {
					name: 'nft-event-ru',
					path: '/nft/sobytiya/:event',
				},
				'tr': {
					name: 'nft-event-tr',
					path: '/nft/etkinlikler/:event',
				},
			},
		}
	},
	'nft-collection': {
		file: '~/pages/nft/collections/[collection].vue',
		children: {
			'index': {
				// 'en': {
				// 	name: 'nft-collection',
				// 	path: '/nft/collections/:collection',
				// },
				'de': {
					name: 'nft-collections-collection-de',
					path: '/nft/kollektionen/:collection',
				},
				'es-mx': {
					name: 'nft-collections-collection-es-mx',
					path: '/nft/colecciones/:collection',
				},
				'fr': {
					name: 'nft-collections-collection-fr',
					path: '/nft/collections/:collection',
				},
				'ja': {
					name: 'nft-collections-collection-jp',
					path: '/nft/collections/:collection',
				},
				'pt-br': {
					name: 'nft-collections-collection-pt-br',
					path: '/nft/colecoes/:collection',
				},
				'ru': {
					name: 'nft-collections-collection-ru',
					path: '/nft/kollektsii/:collection',
				},
				'tr': {
					name: 'nft-collections-collection-tr',
					path: '/nft/koleksiyonlar/:collection',
				},
			},
		}
	},
	'nft-studio': {
		file: '~/pages/nft/studios/[studio].vue',
		children: {
			'index': {
				// 'en': {
				// 	name: 'nft-studio',
				// 	path: '/nft/studios/:studio',
				// },
				'de': {
					name: 'nft-studio-de',
					path: '/nft/studios/:studio',
				},
				'es-mx': {
					name: 'nft-studio-es-mx',
					path: '/nft/estudios/:studio',
				},
				'fr': {
					name: 'nft-studio-fr',
					path: '/nft/studios/:studio',
				},
				'ja': {
					name: 'nft-studio-jp',
					path: '/nft/studios/:studio',
				},
				'pt-br': {
					name: 'nft-studio-pt-br',
					path: '/nft/estudios/:studio',
				},
				'ru': {
					name: 'nft-studio-ru',
					path: '/nft/studii/:studio',
				},
				'tr': {
					name: 'nft-studio-tr',
					path: '/nft/studyolar/:studio',
				},
			},
		}
	},
	// 'en': {
	// 	'nft-events': {
	// 		'': {
	// 			name: 'nft-events-ru',
	// 			path: '/nft/events/'
	// 		},
	// 		'event-type': {
	// 			name: 'nft-events-event-type-ru',
	// 			path: '/nft/events/event-type/:filterValue()'
	// 		},
	// 		'blockchain': {
	// 			name: 'nft-events-blockchain-ru',
	// 			path: '/nft/events/blockchain/:filterValue()'
	// 		},
	// 	},
	// },
	// 'ru': {
	// 	'nft-events': {
	// 		'': {
	// 			name: 'nft-events-ru',
	// 			path: '/nft/sobytiya/'
	// 		},
	// 		'event-type': {
	// 			name: 'nft-events-event-type-ru',
	// 			path: '/nft/sobytiya/event-type/:filterValue()'
	// 		},
	// 		'blockchain': {
	// 			name: 'nft-events-blockchain-ru',
	// 			path: '/nft/sobytiya/blockchain/:filterValue()'
	// 		},
	// 	},
	// }
}
