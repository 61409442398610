import fetchWrapper from '~/api/fetchWrapper';

export default (app, params) => ({
    async fetchFilterId(params) {
        // console.debug('API news.fetchFilterId()');
		const data = {};

		if (params?.filterId?.length) {
			data.filter_id = params.filterId;
		} else {
			if (params?.marketType) {
				data.market_type = params.marketType;
			}

			if (params?.services.length) {
				data.services = params.services.map(item => item.id);
			}

			if (params?.sources.length) {
				data.sources = params.sources.map(item => item.id);
			}

			if (params?.tags.length) {
				data.tags = params.tags.map(item => item.id);
			}
		}
		// console.log('API news.fetchFilterId()', data)
        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/search/filters/`,{
            method: 'POST',
            body: data,
        });

		if (response?.results) {
			if (response.results?.filter_id) {
				return response.results.filter_id;
			} else if (response.results?.filter_data) {
				return response.results.filter_data;
			}
		}

        return response;
    },
	async fetchNews(params) {
		// console.debug('API news.fetchNews()');
		const data = {};

		if (params?.marketType) {
			data.market_type = params.marketType;
		}

		if (params?.services.length) {
			data.services = params.services.map(item => item.id);
		}

		if (params?.sources.length) {
			data.sources = params.sources.map(item => item.id);
		}

		if (params?.tags.length) {
			data.tags = params.tags.map(item => item.id);
		}

		if (params?.page) {
			data.page = params.page;
		}

		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/search/list/`,{
			method: 'POST',
			body: data,
		});

		return response;
	},
    async fetchNewsBySlug(slug) {
        // console.debug('API news.fetchNewsBySlug');

        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/search/news/${slug}/`,{
            method: 'GET',
        });

        return response;
    },
    async fetchInfluencerBySlug(slug) {
        // console.debug('API news.fetchInfluencerBySlug');
		const data = {};

        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/influencer/${slug}/`,{
            method: 'GET',
        });

        return response;
    },
    async fetchTagBySlug(slug) {
        // console.debug('API news.fetchTagBySlug()');
		// console.log('URL', app.$config.public.API_DOXXY_NEWS_URL + `/tag/${slug}/`)
        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/tag/${slug}/`,{
            method: 'GET',
        });

		return response;
    },
	async fetchEntitiesTags() {
		// console.debug('API news.fetchEntitiesTags');
		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/entities/tags/`,{
			method: 'GET',
		});

		return response;
	},
	async fetchEntitiesSources() {
		// console.debug('API news.fetchEntitiesSources()');
		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/entities/sources/`,{
			method: 'GET',
		});

		return response;
	},
	async fetchStatsTags(params) {
        console.debug('API news.fetchStatsTags', params);
		const data = {};

		if (params?.marketType) {
			data.market_type = params.marketType;
		}

		if (params?.services.length) {
			data.services = params.services.map(item => item.id);
		}

		if (params?.sources.length) {
			data.sources = params.sources.map(item => item.id);
		}

		if (params?.tags?.length) {
			data.tags = params.tags.map(item => item.id);
		}

		if (params?.slug) {
			data.slug = params.slug;
		}

		if (params?.timeFrame) {
			data.timeframe = params.timeFrame;
		}

		const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/stats/tags/`,{
            method: 'POST',
            body: data,
        });

        return response;
    },
	async fetchStatsSources(params) {
        console.debug('API news.fetchStatsSources');
		const data = {};

		if (params?.timeFrame) {
			data.timeframe = params.timeFrame;
		}

		if (params?.tag_id) {
			data.tag_id = params.tag_id;
		}

        const response = await fetchWrapper(app,app.$config.public.API_DOXXY_NEWS_URL + `/stats/sources/`,{
            method: 'POST',
            body: data,
        });

        if (response) {
            return response;
        }

        return response;
    },
});
