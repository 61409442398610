<template>
	<div class="page-wrapper">
		<div class="page-container">
			<Header />
			<main class="page-error __next">
				<component :is="errorPage" />
			</main>
		</div>
	</div>
</template>

<script>
import Error404 from '@/components/Error404.vue';
import Errors from '@/components/Errors.vue';
export default {
	props: {
		error: {
			type: Object,
			default: null,
		},
	},
	computed: {
		errorPage() {
			if (this.error.statusCode === '404' || this.error.statusCode === 404) {
				return Error404;
			}
			return Errors;
		},
	},
};
</script>
