import {useStore} from '~/stores/store';
import {useNuxtApp} from '#app';

export default defineNuxtRouteMiddleware(async to => {
    const store = useStore();
    const nuxtApp = useNuxtApp();
    // const { initialize } = useGtag();

    console.debug('%c- middleware DETECT LANGUAGE', 'color:#0a0', process.server ? 'server' : 'frontend');

    // console.log('%c- middleware detectLanguage', 'color:#0a0');
    const domains = nuxtApp.$config.public.domains;

    // console.log('domains', domains);

    const url = useRequestURL();
    const host = url.host;
    // const host = nuxtApp.ssrContext?.event.node.req.headers.host
    const locale = domains[host] ?? 'en';
    // console.log('- host', host, url.pathname, 'detectLocale:', locale);

    // store.lang = domains[host] ?? 'en';
    nuxtApp.$i18n.setLocale(locale);

    // if (process.client) {
    //     console.log('init GA', locale)
    //     initialize(gaTagsByLocale[locale] ?? gaTagsByLocale['default']);
    // }
})
