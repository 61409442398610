<template>
	<h1 class="error__title">Page not found</h1>
	<p class="error__text">Sorry, we couldn’t find the page you are looking for</p>
</template>

<script>
export default {
	name: 'Error404',
};
</script>

<style scoped>
.error__image {
	margin-bottom: 42px;
}
.error__title {
	margin-bottom: 8px;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
}
.error__text {
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
}
/*.parent {*/
/*    width: 100%;*/
/*    height: 30px;*/
/*    display: flex;*/
/*    background-color: #eee;*/
/*    border: 1px solid #ddd;*/
/*}*/
/*.children1:nth-child(1) {*/
/*    width: 88px;*/
/*}*/
/*.children1:nth-child(2) {*/
/*    width: 62px;*/
/*    background-color: #f00;*/
/*}*/
/*.children1:nth-child(3) {*/
/*    width: 16px;*/
/*    background-color: #a00;*/
/*}*/
/*.children1:nth-child(4) {*/
/*    width: 53px;*/
/*    background-color: #800;*/
/*}*/

/*.children2:nth-child(1) {*/
/*    width: 33px;*/
/*}*/
/*.children2:nth-child(2) {*/
/*    width: 82px;*/
/*    background-color: #0f0;*/
/*}*/
/*.children2:nth-child(3) {*/
/*    width: 30px;*/
/*    background-color: #0a0;*/
/*}*/
/*.children2:nth-child(4) {*/
/*    width: 18px;*/
/*    background-color: #080;*/
/*}*/
</style>
