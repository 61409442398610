import revive_payload_client_4sVQNw7RlN from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/__w/doxxy-frontend/doxxy-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/__w/doxxy-frontend/doxxy-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/__w/doxxy-frontend/doxxy-frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/__w/doxxy-frontend/doxxy-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/__w/doxxy-frontend/doxxy-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_8CcCirWtnE from "/__w/doxxy-frontend/doxxy-frontend/plugins/directives.ts";
import ga_V7wqDbvyvO from "/__w/doxxy-frontend/doxxy-frontend/plugins/ga.js";
import helpers_ipnH1tCNhR from "/__w/doxxy-frontend/doxxy-frontend/plugins/helpers.js";
import sentry_client_shVUlIjFLk from "/__w/doxxy-frontend/doxxy-frontend/plugins/sentry.client.ts";
import vue_toastificaton_client_LW56lxPlUl from "/__w/doxxy-frontend/doxxy-frontend/plugins/vue-toastificaton.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  directives_8CcCirWtnE,
  ga_V7wqDbvyvO,
  helpers_ipnH1tCNhR,
  sentry_client_shVUlIjFLk,
  vue_toastificaton_client_LW56lxPlUl
]